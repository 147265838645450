import { useApi } from "~/composables/useApi";
import type { IUser } from "~/types/models/user.interface";

export default defineNuxtRouteMiddleware(async () => {
    const store = useUserStore();
    const user = await useApi("/auth/me");

    if (!user) {
        store.logout();
    } else {
        if (!import.meta.server) {
            const { $identifyUser } = useNuxtApp();
    
            $identifyUser(user as IUser);
        }
    }

    store.login(user as IUser);
});
